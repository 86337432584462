// config.js

const bizIcon = "https://images.marketleader.com/Logos/82/920182_normal.png";
const imageOne = "https://images.marketleader.com/assets/00/2403800_40409692_f.jpg";
const imageTwo = "https://photos.zillowstatic.com/fp/c546a7f3684c6ee11fd1a653e34a102b-p_d.jpg";
const imageThree = "https://www.trulia.com/pictures/thumbs_4/zillowstatic/fp/4ac0a6e782a8e9e7a1039ccc94c2ad07-full.jpg";
const imageFour = "https://ap.rdcpix.com/3b3531ec09d344f8061d0a567ed4f291l-m3079347126rd-w400.webp";
const imageFive = "https://images.marketleader.com/MarketingImages/00/2403800_normal.jpg";

export const siteConfig = {
  title: "Dawn & Darren Duffy Real Estate",
  metaDescription: "Dawn & Darren Duffy Real Estate",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Dawn & Darren Duffy Real Estate",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Dawn & Darren Duffy Real Estate",
    headline: "Your Husband & Wife Agents for Your Real Estate Dream",
    address: "1808 Tamiami Trail, D2 D3, Port Charlotte, FL",
    description: `
      At Dawn & Darren Duffy Real Estate, we take pride in offering a personalized and professional touch to every client. As a dedicated husband-and-wife team, we bring a unique combination of expertise, compassion, and teamwork to the table.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Real Estate Expert in Massachusetts",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "Finding the right home means finding the right community. Let us guide you to neighborhoods that match your lifestyle, with insights on schools, dining, and activities.",
    },
    {
      description: 
        "As a husband-and-wife team with 15+ years of experience in Southwest Florida, we combine our expertise to help you find your dream home.",
    },
    {
      description: 
        "With hundreds of satisfied clients, we’re ready to make your real estate journey seamless and successful.",
    },
  ],
  services: {
    sectionTitle: "Areas we Service",
    description: "Lisa Moccia is proud to serve a diverse range of communities in the Massachusetts area, providing personalized real estate services tailored to each unique neighborhood. From first-time homebuyers to seasoned investors, Lisa brings local market knowledge, community insights, and a commitment to finding the perfect home for each client.",
    callouts: [
      {
        name: 'Port Charlotte',
        description: 'A quiet neighborhood with friendly neighbors, great schools, beach vibes, and well-lit streets—perfect for retirees and families alike.',
        imageSrc: imageTwo,
        imageAlt: 'Port Charlotte Community',
      },
      {
        name: 'Punta Gorda',
        description: 'A charming mix of historic character and modern living, ideal for those seeking a vibrant yet relaxed lifestyle.',
        imageSrc: imageThree,
        imageAlt: 'Punta Gorda Community',
      },
      {
        name: 'Sarasota',
        description: 'A community celebrated for its rich culture, beautiful beaches, and diverse housing options.',
        imageSrc: imageFour,
        imageAlt: 'Sarasota Community',
      },
    ],
    otherServices: [
      "Englewood FL",
      "North Port, FL",
    ]
  },
  about: {
    sectionTitle: "About Dawn & Darren Duffy Real Estate",
    description: "We bring a wealth of knowledge on local communities, current market conditions, and the home buying and selling process. We strive to provide exceptional service every step of the way, so we can provide you with a real estate experience that exceeds your expectations.",
    image: {
      src: imageFive,
      alt: "Dawn & Darren Duffy Real Estate, real estate agent",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
  destinationAddress: "1808 Tamiami Trail, Port Charlotte, FL, 33948",
  googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.4442065669837!2d-82.09779588494629!3d27.004693683071803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db4f1c7ae4b4a7%3A0x6a9a9074e454762e!2s1808%20Tamiami%20Trail%2C%20Port%20Charlotte%2C%20FL%2033948!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
  subCategories: [
    { 
      name: 'Apple Maps', 
      url: 'https://maps.apple.com/?address=1808%20Tamiami%20Trail,%20Port%20Charlotte,%20FL%2033948&ll=27.0047,-82.0978&q=1808%20Tamiami%20Trail' 
    },
    { 
      name: 'Bing Maps', 
      url: 'https://www.bing.com/maps?q=1808+Tamiami+Trail,+Port+Charlotte,+FL+33948&FORM=HDRSC6' 
    },
    { 
      name: 'Google Earth', 
      url: 'https://earth.google.com/web/search/1808+Tamiami+Trail,+Port+Charlotte,+FL+33948/@27.0047,-82.0978,15a,814.57589625d,35y,0h,0t,0r' 
    },
    { 
      name: 'Google Maps', 
      url: 'https://goo.gl/maps/jT2yBx8oUXLoFzUv8' 
    }
  ]
  },
  contact: {
    sectionTitle: "Ready to Find Your Dream Home?",
    description: "Contact Dawn & Darren Duffy Real Estate to discuss your real estate needs or to schedule a consultation. Whether you're buying, selling, or investing, both Dawn & Darren Duffy are here to provide expert guidance every step of the way.",
    socialLinks: {
      facebook: "https://www.dawndarrenrealestate.com/",     
      instagram: "https://www.dawndarrenrealestate.com/",     
      twitter: "https://www.dawndarrenrealestate.com/",     
      linkedin: "https://www.dawndarrenrealestate.com/",     
      youtube: "https://www.dawndarrenrealestate.com/",     
    },
    contactNumbers: [
      "Mobile: (941) 268-1716",
      "Office: (941) 518-0267"
    ],
    logo: bizIcon,
    emailRecipient: "3drealty1@gmail.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
